<template>
  <div id="app">
    <AgreementTip v-if="tip" @hide="tip = false" />
    <router-view />
  </div>
</template>

<script>
import AgreementTip from "@/components/AgreementTip.vue";

export default {
  components: {
    AgreementTip,
  },
  data() {
    return {
      tip: false,
    };
  },
  created() {
    const currentTime = new Date().getTime();
    const agreeTime = window.localStorage.getItem("agreeTime");
    console.log("###### ~ agreeTime:", agreeTime);
    // 没有点击接受，或点击接受的时间超过一天，显示协议
    this.tip = !agreeTime || currentTime - 24 * 60 * 60 * 1000 > Number(agreeTime);
  },
};
</script>

<style lang="less">
@import './mobile.less';
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: PingFangSC, PingFang SC, Microsoft YaHei, "微软雅黑", Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000037;
}
div{
  box-sizing: border-box;
}
</style>
