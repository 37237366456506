<template>
  <div class="agreement">
    <div class="box">
      <div class="logo">
        <img src="../assets/logo.png" />
      </div>
      <div class="agreementTitle">投资者承诺</div>
      <div class="agreementContent">
        在继续浏览浅行私募网站前，请您确认您或您所代表的机构是一名“合格投资者”。
        各国家及地区的证券及投资法律规定，仅“合格投资者”有资格投资私募证券投资基金。根据我国《私募投资基金监督管理暂行办法》的规定，私募基金合格投资者的标准为：
        <br /><br />
        一、具备相应风险识别能力和风险承担能力，投资于单只私募基金的金额不低于100万元且符合下列相关标准的单位和个人：<br />
        1、净资产不低于1000万元的单位；<br />
        2、金融资产不低于300万元或者最近三年个人年均收入不低于50万元的个人。(前款所称金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等。)<br /><br />
        二、下列投资者视为合格投资者：<br />
        1、社会保障基金、企业年金等养老基金、慈善基金等社会公益基金；<br />
        2、依法设立并在基金业协会备案的投资计划；<br />
        3、投资于所管理私募基金的私募基金管理人及其从业人员；<br />
        4、中国证监会规定的其他投资者。<br /><br />
        如果您继续访问或使用本网站及其所载资料，需您声明及保证您或您所代表的机构为“合格投资者”，并将遵守对您适用的司法区域的有关法律及法规，同意并接受以下条款及相关约束。如果您不符合“合格投资者”标准或不同意下列条款及相关约束，请勿继续访问本网站。<br /><br />
        “本网站”指由浅行私募基金管理（海南）有限公司（以下简称“浅行私募”）所有并发布的网站及其所载信息及资料。本网站所载信息及资料仅供参考，并不构成广告或分销、销售等要约，或招揽买入任何证券、基金或其他投资工具的邀请或要约。<br /><br />
        本网站所提供的资料仅供参考而不能构成投资和咨询意见。投资涉及风险，投资产品过往业绩并不能预示未来收益，投资者应进一步获取相关基金的发售资料，并了解有关投资所涉及的风险因素，以在适当的投资和咨询意见下作出投资决策。
        <br /><br />
        浅行私募有权更改或修订本网站所载信息及资料而免除事前通知，且并不承诺定期更新本网站信息及资料。<br /><br />
        与本网站所载信息及资料有关的所有版权、专利权、知识产权及其他产权均为浅行私募所有，且浅行私募概不对外转让上述权利。<br /><br />
      </div>
      <div class="btn">
        <el-button @click="renounce">放弃</el-button>
        <el-button @click="accept">接受</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgreementTip",
  methods: {
    renounce() {
      window.history.go(-1);
    },
    accept() {
      window.localStorage.setItem("agreeTime", new Date().getTime());
      this.$emit("hide");
    },
  },
};
</script>

<style scoped lang="less">
.agreement {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #22195e;
  color: #ffffffe6;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    padding-bottom: 75px;
    height: 45px;
    overflow: hidden;
    > img {
      height: 100%;
    }
  }
  .agreementTitle {
    font-size: 20px;
    color: #fff;
  }
  .agreementContent {
    text-align: left;
    max-width: 1500px;
    width: 90%;
    height: 300px;
    line-height: 32px;
    margin: 20px auto;
    overflow-y: auto;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding: 15px 45px 15px 0;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 6px;
    }
  }
  .btn {
    .el-button {
      width: 190px;
      background-color: rgba(255, 255, 255, 0);
      color: #fff;
      border-color: #ffffffc9;
      overflow: hidden;
    }
    .el-button + .el-button {
      margin-left: 20px;
      // animation: animated-border 1.5s infinite;
    }
  }
}
@keyframes animated-border {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}
</style>
