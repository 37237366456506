<template>
  <div class="contact">
    <el-popover placement="top" width="154" transition="custom" trigger="hover" :visible-arrow="false"
      popper-class="codeBox">
      <img style="width: 154px; height: 154px; margin-bottom: -5px" src="../assets/wxCode2.png" alt="" />
      <img slot="reference" src="../assets/wx.png" alt="" />
    </el-popover>
    <el-popover placement="top" width="276" transition="custom" trigger="hover" :visible-arrow="false"
      popper-class="codeBox">
      <!-- <div class="tip">招聘联系邮箱<br />Recruiting@acsense-asset.com</div> -->
      <div class="tip" @click="copyEmail">
        <img style="width: 217px; height: 51px; margin-bottom: -5px" src="../assets/emailSit.png" alt="" />
      </div>
      <img slot="reference" src="../assets/email.png" alt="" />
    </el-popover>
    <el-popover placement="top" width="160" transition="custom" trigger="hover" :visible-arrow="false"
      popper-class="codeBox">
      <!-- <div class="tip" @click="makePhoneCall">电话<br />028-86112646</div> -->
      <div class="tip" @click="makePhoneCall">
        <img style="width: 117px; height: 48px; margin-bottom: -5px" src="../assets/phoneNumber.png" alt="" />
      </div>
      <img slot="reference" src="../assets/phone.png" alt="" />
    </el-popover>

    <div class="tipBox" v-if="tipCont">{{ tipCont }}</div>
  </div>
</template>

<script>
export default {
  name: "ContactWay",
  data() {
    return {
      tipCont: ''
    }
  },
  methods: {
    makePhoneCall() {
      // 使用tel协议来触发拨号动作
      window.location.href = 'tel:028-86112646';
    },
    copyEmail() {
      const specifiedText = 'Recruiting@acsense-asset.com'; // 要复制的内容

      // 创建一个 textarea 元素并设置内容
      const textarea = document.createElement('textarea');
      textarea.value = specifiedText;

      // 将 textarea 添加到 DOM 中
      document.body.appendChild(textarea);

      // 选中 textarea 中的内容
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length); // 兼容性处理

      // 执行复制操作
      document.execCommand('copy');

      // 移除 textarea
      document.body.removeChild(textarea);

      // 提示复制成功或失败
      if (document.queryCommandSupported('copy')) {
        if (window.innerWidth <= 600) {
          this.tipCont = '复制成功！'
        } else {
          this.$message.success('复制成功！');
        }
      } else {
        if (window.innerWidth <= 600) {
          this.tipCont = '复制失败！'
        } else {
          this.$message.error('复制失败！');
        }
      }
      setTimeout(() => {
        this.tipCont = ''
      }, 3000)
    }
  }
};
</script>

<style scoped lang="less">
.px2vw(@attr, @px) {
  @{attr}: calc(@px / 1920 * 100vw);
}

.contact {
  display: flex;
  justify-content: center;
  .px2vw(gap, 80);

  img {
    .px2vw(width, 60);
    transition: transform 0.5s;
    transform: scale(1);

    &:hover {
      transition: transform 0.5s;
      transform: scale(1.3);
    }
  }
}

.tip {
  text-align: center;
  font-size: 16px;
  color: #000037;
  line-height: 24px;
  text-shadow: 0px 2px 14px rgba(0, 0, 55, 0.08);
  padding: 20px 0;
}

.imgBox {
  overflow: hidden;
}

.tipBox {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  color: #fff;
}
</style>
<style>
.codeBox {
  box-shadow: 0px 2px 14px 0px #00003714 !important;
  border-radius: 12px !important;
  border: none !important;
  padding: 0 !important;
}

.custom-enter-active,
.custom-leave-active {
  transition: opacity 0.5s, transform 0.5s;
  opacity: 1;
  transform: scale(1);
}

.custom-enter,
.custom-leave-to {
  opacity: 0;
  transform: scale(0.1);
}
</style>
