<template>
  <div class="carousel">
    <el-carousel :autoplay="false" indicator-position="none" type="card" :height="`${(376 / 1920) * 100}vw`"
      @change="change">
      <el-carousel-item :ref="'item' + idx" v-for="(item, idx) in jsonData" :key="idx">
        <div class="itemBg">
          <div class="item" @click.stop="postItem(item?.title)">
            <div class="up">
              <img :src="item?.icon" alt="" />
            </div>
            <div class="mid">{{ item?.title }}</div>
            <div class="down">{{ item?.department }} | {{ item?.area }}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="mCarousel" :ref="'item' + idx" v-for="(item, idx) in jsonData" :key="idx">
      <div class="item" @click.stop="postItem(item?.title)">
        <div class="left">
          <img :src="item?.icon" alt="" />
        </div>
        <div class="right">
          <div class="mid">{{ item?.title }}</div>
          <div>{{ item?.department }} | {{ item?.area }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostCarousel",
  data() {
    return {
      jsonData: [],
      timer:null
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$nextTick(() => {
      this.timer = setInterval(()=>{
        this.change();
      },3000)
    });
  },
  destroyed(){
    clearInterval(this.timer)
  },
  methods: {
    fetchData() {
      fetch("post.json")
        .then((response) => response.json())
        .then((data) => {
          console.log("###### ~ data:", data);
          this.jsonData = data;
          this.$nextTick(() => {
            this.change();
          });
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    },
    postItem(title) {
      window.open("/post?t=" + title);
    },
    change() {
      this.jsonData.forEach((v, i) => {
        this.$refs["item" + i].forEach((item) => {
          item.scale = 1;
        })
      });
    },
  },
};
</script>

<style scoped lang="less">
.px2vw(@attr, @px) {
  @{attr}: calc(@px / 1920 * 100vw);
}

.carousel {
  .mCarousel {
    display: none
  }

  .px2vw(width, 1500);
  margin: 0 auto;

  .itemBg {
    .px2vw(width, 356);
    .px2vw(height, 362);
    margin: 14px auto 0;
    background: #e5edf9;
    border-radius: 4px;
    position: relative;

    .item {
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #e4edfa;
      position: absolute;
      left: -14px;
      top: -14px;
      z-index: 9;

      .up {
        .px2vw(padding-top, 79);
        .px2vw(padding-bottom, 31);

        >img {
          .px2vw(width, 100);
        }
      }

      .mid {
        .px2vw(font-size, 30);
        .px2vw(line-height, 50);
      }

      .down {
        .px2vw(font-size, 20);
        .px2vw(line-height, 30);
        padding-top: 8px;
      }
    }
  }
}
</style>
