<template>
  <div class="home">
    <div class="homeHeader" :class="{ scrolled, menuClicked }">
      <div class="left">
        <img v-if="scrolled" src="../assets/logo2.png" />
        <img v-else src="../assets/logo.png" />
      </div>
      <div class="menuItem" v-if="menuClicked" @click="menuClicked = false">
        <div @click.stop="()=>{}">
          <el-button type="text" @click="menuClicked = false">首页</el-button>
          <el-button type="text" @click="menuClicked = false; showCode = true">投资者平台</el-button>
        </div>
      </div>
      <div class="right">
        <div>
          <el-button type="text">首页</el-button>
          <el-button type="text" @click="postItem">加入我们</el-button>
          <el-button type="text" @click="showCode = true">投资者平台</el-button>
        </div>
      </div>
      <div class="mMenu" @click="menuClicked = !menuClicked"></div>
    </div>
    <el-carousel ref="carousel" :autoplay="false" :loop="false" indicator-position="none" arrow="never"
      direction="vertical" height="100vh" @change="(num) => (scrolled = !!num)">
      <el-carousel-item>
        <div class="one">
          <div class="bg"></div>
          <div class="text fc">
            <div class="text1">在浅行私募</div>
            <div class="text2">我们对低效说不 <span></span> 我们充满好奇 我们谨慎地冒险</div>
          </div>
          <div class="next" @click="nextPage">
            <i class="el-icon-bottom"></i>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="two fc">
          <div class="left imgBox">
            <div class="tp1">
              <img src="../assets/tp1.png" alt="" />
            </div>
            <div class="tp2">
              <img src="../assets/tp2.png" alt="" />
            </div>
          </div>
          <div class="right">浅行私募是一家以逻辑与数据<span></span>为驱动的量化投资公司</div>
        </div>
        <div class="next" @click="nextPage"></div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="three fc">
          <div class="left">我们运用数理、金融与计算机科学<span></span>知识探索并提升中国证券市场有效性</div>
          <div class="right imgBox">
            <div class="hp1">
              <img src="../assets/hp1.png" alt="" />
            </div>
            <div class="hp2">
              <img src="../assets/hp2.png" alt="" />
            </div>
            <div class="hp3">
              <img src="../assets/hp3.png" alt="" />
            </div>
            <div class="hp4">
              <img src="../assets/hp4.png" alt="" />
            </div>
          </div>
        </div>
        <div class="next" @click="nextPage"></div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="four fc">
          <div class="cont imgBox">
            <div class="item">
              <div class="up">
                <img src="../assets/fu1.png" alt="" />
              </div>
              <div class="mid">
                <img src="../assets/fd1.png" alt="" />
              </div>
              <div class="text">投研技术人员</div>
            </div>
            <div class="item">
              <div class="up">
                <img src="../assets/fu2.png" alt="" />
              </div>
              <div class="mid">
                <img src="../assets/fd2.png" alt="" />
              </div>
              <div class="text">利润投入研发</div>
            </div>
            <div class="item">
              <div class="up">
                <img src="../assets/fu3.png" alt="" />
              </div>
              <div class="mid">
                <img src="../assets/fd3.png" alt="" />
              </div>
              <div class="text">数据累积量</div>
            </div>
          </div>
        </div>
        <div class="next" @click="nextPage"></div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="five">
          <div class="bgl">
            <img src="../assets/fbgl.png" alt="" />
          </div>
          <div class="bgr">
            <img src="../assets/fbgr.png" alt="" />
          </div>
          <div class="up">期待你的加入</div>
          <div class="mid">
            <PostCarousel />
          </div>
          <div class="down">
            <ContactWay />
          </div>
          <div class="homeFooter">
            Copyright ◎ 浅行私募基金管理(海南)有限公司版权所有 琼ICP备2023003023号-1
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="wxCode fc imgBox" v-show="showCode" @click="showCode = false">
      <div class="code" @click.stop="() => { }">
        <img src="../assets/wxCode.png" alt="" />
        <img src="../assets/mWxCode.png" alt="" />
      </div>
      <div class="close" @click="showCode = false">
        <img src="../assets/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import PostCarousel from "@/components/PostCarousel.vue";
import ContactWay from "@/components/ContactWay.vue";

export default {
  name: "HomeView",
  components: {
    PostCarousel,
    ContactWay,
  },
  data() {
    return {
      showCode: false,
      scrolled: false,
      menuClicked: false,
      isMovingBanner: false,
    };
  },
  methods: {
    postItem() {
      window.open("/post");
    },
    nextPage() {
      this.$refs.carousel.next();
    },
    slideBanner() {
      let that = this;
      const userAgent = navigator.userAgent;
      //获取走马灯组件
      var box = document.querySelector(".el-carousel__container");
      box.addEventListener("wheel", (e) => {
        if (e.deltaX != 0) {
          //判断 如果是水平滚动则忽略本次事件
          return;
        }
        //使用一个标识来判断当前是否正在切换轮播图，防止切换时抖动
        if (that.isMovingBanner) {
          return;
        }

        if (e.deltaY < 0 || e.detail < 0) {
          //向上滑动
          that.isMovingBanner = true;
          that.$refs.carousel.prev();
        } else {
          //向下滑动
          that.isMovingBanner = true;
          that.$refs.carousel.next();
        }

        if (userAgent.match(/Windows/i)) {
          // Windows 系统
          setTimeout(() => {
            that.isMovingBanner = false;
          }, 200);
        } else {
          // iOS 系统
          setTimeout(() => {
            that.isMovingBanner = false;
          }, 1000);
        }
      });

      // 记录开始触摸的位置
      let startX, startY;

      // 为元素添加触摸开始的事件监听
      box.addEventListener("touchstart", function (e) {
        // 获取第一个触点的位置
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
      });

      // 为元素添加触摸结束的事件监听
      box.addEventListener("touchend", function (e) {
        //使用一个标识来判断当前是否正在切换轮播图，防止切换时抖动
        if (that.isMovingBanner) {
          return;
        }
        // 获取最后一个触点的位置
        var endX = e.changedTouches[0].clientX;
        var endY = e.changedTouches[0].clientY;

        // 计算滑动的距离
        var distanceX = endX - startX;
        var distanceY = endY - startY;

        // 根据水平或垂直距离判断滑动方向
        if (Math.abs(distanceY) > Math.abs(distanceX) && distanceY > 0) {
          console.log("向下滑动");
          that.isMovingBanner = true;
          that.$refs.carousel.prev();
        } else if (Math.abs(distanceY) > Math.abs(distanceX) && distanceY < 0) {
          console.log("向上滑动");
          that.isMovingBanner = true;
          that.$refs.carousel.next();
        }
        if (userAgent.match(/Windows/i)) {
          // Windows 系统
          setTimeout(() => {
            that.isMovingBanner = false;
          }, 200);
        } else {
          // iOS 系统
          setTimeout(() => {
            that.isMovingBanner = false;
          }, 1000);
        }
      });
    },
  },
  mounted() {
    this.slideBanner();
  },
};
</script>

<style lang="less">
.px2vw(@attr, @px) {
  @{attr}: calc(@px / 1920 * 100vw);
}

.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  .wxCode {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(34, 25, 94, 0.7);
    flex-direction: column;
    padding: 0 43px;

    .code {
      width: 100%;
      max-width: 420px;

      :last-child {
        display: none;
      }
    }

    .close {
      width: 32px;
      margin-top: 23px;
      cursor: pointer;
    }
  }

  .homeHeader {
    position: absolute;
    left: 0;
    // .px2vw(top, 50);
    top: 26px;
    z-index: 99;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
    box-sizing: border-box;

    .mMenu {
      display: none;
    }

    .left {
      .px2vw(height, 46);

      >img {
        height: 100%;
      }
    }

    .right {
      .el-button {
        color: #fff;
        .px2vw(font-size, 24);
        padding: 0;
        line-height: 36px;
        font-weight: normal;

        &:first-child {
          font-weight: 600;
        }
      }

      .el-button+.el-button {
        margin-left: 48px;
      }
    }

    &.scrolled {
      .right .el-button {
        color: #000037;
      }
    }
  }

  .one,
  .two,
  .three,
  .four,
  .five {
    width: 100vw;
    height: 100vh;
  }

  .one {
    color: #fff;
    overflow: hidden;
    position: relative;

    .bg {
      height: 100%;
      animation: scale 10s infinite;
      background: url("../assets/hbg.jpg") center/cover no-repeat;
    }

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      transform: translate(-50%, -50%);
      flex-direction: column;

      .text1 {
        .px2vw(font-size, 36);
        padding-bottom: 24px;
      }

      .text2 {
        .px2vw(font-size, 60);
        font-weight: 500;
      }
    }
  }

  .two {
    background-color: #fff;
    gap: 78px;

    .left {
      .px2vw(width, 606);
      .px2vw(height, 558);
      background: url("../assets/tlbg.png") left/contain no-repeat;
      position: relative;

      >div:hover {
        // animation: shake-slow 5s infinite ease-in-out;
        animation: shake 0.5s ease-in-out;
      }

      .tp1 {
        position: absolute;
        .px2vw(left, 55);
        .px2vw(width, 404);
        .px2vw(height, 190);
        bottom: 0;
        z-index: 1;
      }

      .tp2 {
        position: absolute;
        .px2vw(left, 169);
        .px2vw(bottom, 148);
        .px2vw(width, 438);
        .px2vw(height, 387);
        z-index: 3;
      }
    }

    .right {
      .px2vw(width, 816);
      .px2vw(font-size, 48);
      text-align: left;
      // padding-left: 78px;
      line-height: 72px;
    }
  }

  .three {
    background: linear-gradient(90deg, #f7fdfd 0%, #e7f8fa 45%, #f0f2fe 100%);
    gap: 70px;

    .left {
      .px2vw(width, 768);
      .px2vw(font-size, 48);
      // padding-right: 70px;
      text-align: left;
      line-height: 72px;
      >span{
        display: block;
      }
    }

    .right {
      .px2vw(width, 696);
      .px2vw(height, 614);
      background: url("../assets/hrbg.png") right/contain no-repeat;
      position: relative;

      >div:hover {
        // animation: shake 5s infinite ease-in-out;
        animation: shake 0.5s ease-in-out;
      }

      .hp1 {
        position: absolute;
        .px2vw(left, 295);
        .px2vw(bottom, 42);
        .px2vw(width, 343);
        .px2vw(height, 131);
        z-index: 4;
      }

      .hp2 {
        position: absolute;
        left: 0;
        .px2vw(bottom, 106);
        .px2vw(width, 425);
        .px2vw(height, 425);
        z-index: 3;
      }

      .hp3 {
        position: absolute;
        .px2vw(left, 390);
        .px2vw(bottom, 290);
        .px2vw(width, 246);
        .px2vw(height, 131);
        z-index: 2;
      }

      .hp4 {
        position: absolute;
        .px2vw(left, 422);
        .px2vw(bottom, 210);
        .px2vw(width, 251);
        .px2vw(height, 251);
        z-index: 1;
      }
    }
  }

  .four {
    background-color: #fff;

    .cont {
      background: url("../assets/fbg.png") top/contain no-repeat;
      .px2vw(width, 1500);
      .px2vw(min-height, 656);
      .px2vw(padding-top, 200);
      .px2vw(padding-left, 50);
      .px2vw(padding-right, 50);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .item {
        background-color: #fff;
        box-shadow: 0px 4px 4px 0px rgba(237, 237, 237, 0.5);
        border: 1px solid #f8f8f8;
        box-sizing: border-box;
        .px2vw(padding, 30);
        .px2vw(width, 400);
        .px2vw(font-size, 30);
        .px2vw(line-height, 50);

        .up {
          overflow: hidden;
          .px2vw(width, 340);
          .px2vw(height, 250);

          >img {
            height: 100%;

            &:hover {
              animation: scale 6s infinite;
            }
          }
        }

        .mid {
          .px2vw(padding-top, 38);
          .px2vw(padding-bottom, 11);

          >img {
            .px2vw(height, 30);
          }
        }
      }
    }
  }

  .five {
    position: relative;

    // background: url("../assets/fbgl.png") 0% 227px/226px 207px no-repeat,
    //   url("../assets/fbgr.png") right/754px 187px no-repeat;
    .bgl {
      position: absolute;
      left: 0;
      .px2vw(top, 227);
      .px2vw(width, 226);

      >img {
        width: 100%;
      }
    }

    .bgr {
      position: absolute;
      right: 0;
      .px2vw(top, 430);
      .px2vw(width, 754);

      >img {
        width: 100%;
      }
    }

    .up {
      .px2vw(padding, 80);
      .px2vw(font-size, 48);
      .px2vw(line-height, 72);
    }

    .mid {
      max-width: 1500px;
      margin: 0 auto;
    }

    .down {
      .px2vw(padding-top, 80);
      .px2vw(padding-bottom, 80);
    }
  }
}

@media screen and (min-width: 600px) {
  .homeFooter {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 99;
    .px2vw(font-size, 20);
    .px2vw(height, 100);
    .px2vw(line-height, 100);
    background-color: #22195e;
    color: #fff;
  }
}

.fc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgBox {
  >div>img {
    width: 100%;
  }
}

.next {
  position: absolute;
  left: calc(50% - 22px);
  bottom: calc(50% - 50vh + 20px);
  width: 48px;
  height: 48px;
  color: #fff;
  animation: move 0.6s linear 1s infinite alternate;
  cursor: pointer;
  .px2vw(font-size, 32);

  >i {
    height: 48px;
  }

  &:hover {
    animation: none;
    color: red;
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-8px);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    transform-origin: center;
  }

  35% {
    transform: scale(1.1);
    transform-origin: center;
  }

  100% {
    transform: scale(1);
    transform-origin: center;
  }
}

@keyframes shake {
  30% {
    transform: translate(6px, -2px) rotate(3.5deg);
  }

  60% {
    transform: translate(4px, 8px) rotate(0.5deg);
  }

  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

@keyframes moveAround {
  0% {
    left: 40px;
    top: 0px;
  }

  28.93% {
    left: 360px;
    top: 0px;
  }

  33.99% {
    left: 400px;
    top: 40px;
  }

  44.82% {
    left: 400px;
    top: 160px;
  }

  49.88% {
    left: 360px;
    top: 200px;
  }

  78.81% {
    left: 40px;
    top: 200px;
  }

  83.87% {
    left: 0px;
    top: 160px;
  }

  94.70% {
    left: 0px;
    top: 40px;
  }

  100% {
    left: 40px;
    top: 0px;
  }
}
</style>
